import React, { Component } from 'react';
import "./summary.css";
import Button from "../../components/price/button/button";
import { Link } from 'react-router-dom'

class Summary extends Component {
    state = { 
        membership : this.props.location.state.membership,
        price: this.props.location.state.membership.price,
        participantsButtons: [
        
            {name:"100",
            active:true
            },

            {name:"250",
            active:false
            },

            {name:"500",
            active:false
            }

        ],
     }

    updateTimeButtons=(button)=>{
        if(button.name===this.state.participantsButtons[0].name){
            if(button.active===false && this.state.participantsButtons[0].active===false){
                this.setState(()=>({
                    participantsButtons: [
            
                        {name:this.state.participantsButtons[0].name,
                        active:true
                        },
                
                        {name:this.state.participantsButtons[1].name,
                        active:false
                        },

                        {name:this.state.participantsButtons[2].name,
                        active:false
                        }
                
                        ]
                }));
                const p=this.state.membership.prices.members[0].price;
                const pyearly=p-(p*(this.state.membership.prices.yearly/100));
                this.state.membership.price===this.state.membership.prices.members[0].price ? this.setState({price : p}) : this.setState({price : pyearly});
            }
        }
        if(button.name===this.state.participantsButtons[1].name){
            if(button.active===false && this.state.participantsButtons[1].active===false){
                this.setState(()=>({
                    participantsButtons: [
            
                        {name:this.state.participantsButtons[0].name,
                        active:false
                        },
                
                        {name:this.state.participantsButtons[1].name,
                        active:true
                        },

                        {name:this.state.participantsButtons[2].name,
                        active:false
                        }
                
                        ]
                }));
                const p=this.state.membership.prices.members[1].price;
                const pyearly=p-(p*(this.state.membership.prices.yearly/100));
                this.state.membership.price===this.state.membership.prices.members[0].price ? this.setState({price : p}) : this.setState({price : pyearly});
            }
        }
        if(button.name===this.state.participantsButtons[2].name){
            if(button.active===false && this.state.participantsButtons[2].active===false){
                this.setState(()=>({
                    participantsButtons: [
            
                        {name:this.state.participantsButtons[0].name,
                        active:false
                        },
                
                        {name:this.state.participantsButtons[1].name,
                        active:false
                        },

                        {name:this.state.participantsButtons[2].name,
                        active:true
                        }
                
                        ]
                }));
                const p=this.state.membership.prices.members[2].price;
                const pyearly=p-(p*(this.state.membership.prices.yearly/100));
                this.state.membership.price===this.state.membership.prices.members[0].price ? this.setState({price : p}) : this.setState({price : pyearly});
            }
        }
    }

    render() { 
        return ( 
            <div className="container-fluid summary-section">
                <div className="container">
                    <h1 className="type">{this.state.membership.type}</h1>
                    <ul className="functionalities">
                        {this.state.membership.properties.map((p,index)=>p.enabled ?
                        <li className="row functionality" key={index}>
                            <span className="col-6">{p.name}</span>
                            <p className="col-6">{p.description}</p>
                        </li>: "")}
                    </ul>
                    <div className="row subscription">
                        <span className="col-12">Subscription type</span>
                        <span className="col-lg-3 col-sm-12 period">{this.state.membership.period}</span>
                    </div>
                    <div className="row participants">
                        <span>Number of participants</span>
                        {this.state.participantsButtons.map((p,index)=><Button 
                            key={index} 
                            name={p.name} 
                            active={p.active} 
                            updateTimeButtons={this.updateTimeButtons}
                         />)}
                    </div>
                    <div className="price">
                        <span>{this.state.price} RON/MONTH</span>
                    </div>
                    <div className="purchase">
                    <Link to={{
                        pathname : "/summary",
                        state: {
                        }
                    }} className="btn membership-button">PURCHASE NOW</Link>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default Summary;
