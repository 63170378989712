import React from 'react';
import "./project.css";

function Project(props){
    return(
        <div className={"project "+props.classname}>
            <div className="image">
                <img src={require("../../../../images/"+props.image)} alt={props.image}/>
            </div>
            <div className="info">
                {/* <div className="logo">
                    <img src={require("../../../../images/"+props.logo)}/>
                </div> */}
                <div className="title">
                    <h2>{props.name}</h2>
                </div>
                <div className="description">
                    <p>{props.description}</p>
                </div>
            </div>
        </div>
    );
}

export default Project