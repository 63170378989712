import React from 'react';
import "./portofolio.css";
import Project from "./project/project";

function Portofolio(){
    let data =[
        {
            image:"live_on_air.jpg",
            logo:"playground_logo.png",
            name:"🔴 Live On Air",
            description:"We designed a live stream tool which integrates seamless the software capabilities with the powerness of advance video and audio hardware",
        }
    ];
    return ( 
       <div className="portofolio-section container-fluid" id="portofolio">
            <div className="container">
                <div className="portofolio-description">
                    <h2>Our Portofolio</h2>
                    <p>Use the perfect way to grow your business, check out our solutions.</p>
                </div>
                {data.map((d,index) => <Project key={index}  classname={index%2===0 ? "left" : "right"} icon={d.icon} name={d.name} image={d.image} description={d.description} logo={d.logo}/>)}
            </div>
         </div>
     );
}
 
export default Portofolio;