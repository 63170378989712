import React from 'react';
import "./service.css";

function Service(props){
    return ( 
       <div className="service">
            <div className="icon-container">
                <img src={require("../../../../images/"+props.icon)} alt={props.decription}/>
            </div>
            <div className="category">
                <h3>{props.category}</h3>
            </div>
            <div className="description">
                <p>{props.description}</p>
            </div>
        </div>
    );
}
 
export default Service;