import React, { Component } from 'react';
import "./price.css";
import Button from "./button/button";
import Membership from "./membership/membership";

class Price extends Component {
    state = { 
        membershipType: "monthly",
        active: true,
        timeButtons: [
        
            {name:"Monthly",
            active:true
            },

            {name:"Yearly",
            active:false
            }

        ],
               
        memberships: require('./memberships.json')
    }

    updateTimeButtons=(button)=>{
        if(button.name==="Monthly"){
            if(button.active===false && this.state.timeButtons[0].active===false){
                this.setState(()=>({
                    timeButtons: [
            
                        {name:this.state.timeButtons[0].name,
                        active:true
                        },
                
                        {name:this.state.timeButtons[1].name,
                        active:false
                        }
                
                        ]
                }));
            this.setState((state =>{
                return {membershipType : "monthly"};
            }))
            }
        }
        if(button.name==="Yearly"){
            if(button.active===false && this.state.timeButtons[1].active===false){
                this.setState(()=>({
                    timeButtons: [
            
                        {name:this.state.timeButtons[0].name,
                        active:false
                        },
                
                        {name:this.state.timeButtons[1].name,
                        active:true
                        }
                
                        ]
                }));
                this.setState({membershipType : "yearly"});
            }
        }
    }

    changeAvtiveMembership=(membership)=>{
        membership.active===true ? this.setState({active : true}) : this.setState({active : false});
    }

    componentDidMount(){
       
    }

    render() { 
        return ( 
            <div className="container-fluid price-section" id="price">
                <div className="container">
                    <div className="price-content">
                        <h2 className="price-h2">Our pricing</h2>
                        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                         fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                         officia deserunt mollit anim id est laborum.</p>
                    </div>
                    <div className="price-options">
                        <div className="row">
                            <div className="col-12 button-options">
                                {this.state.timeButtons.map((b,index) => <Button 
                                key={index} 
                                name={b.name} 
                                active={b.active} 
                                updateTimeButtons={this.updateTimeButtons}
                                />)}
                            </div>
                        </div>
                        <div className="memberships-row row">
                            {this.state.memberships.map((m) => <Membership 
                            key={m.id} 
                            id={m.id}
                            type={m.type} 
                            price={m.price} 
                            properties={m.properties}
                            membershipType={this.state.membershipType} 
                            updateActive={this.changeAvtiveMembership}
                            active={this.state.active}
                            />)}
                        </div>   
                    </div>
                </div>
            </div>
         );
    }

}
 
export default Price;
