import React from 'react';
import "./services.css";
import Service from './service/service';

function Portofolio(){
    let data =[
        {
            icon:"settings.png",
            category:"Custom Apps Development",
            description:"We develop custom applications that are perfectly designed for serving the unique processes of your business"
        },{
            icon:"web.png",
            category:"Web Apps Development",
            description:"Developing custom and outsourced web applications with reliable performance and flawless user experience"
        },{
            icon:"mobile.png",
            category:"Mobile Apps Development",
            description:"Our team also offers professional mobile app development services for iOS and Android platforms"
        },{
            icon:"integration.png",
            category:"App Integration",
            description:"Our team also provides the client with quality app integration service that improve its performance"
        }
    ];
    return ( 
       <div className="services-section container-fluid" id="services">
            <div className="container">
                <div className="services-description">
                    <h2>Our Services</h2>
                    <p>Fivetech Software team is able to build mobile and web applications from scratch. Our team also provides system architecture design, user interface design and quality assurance services.</p>
                </div>
                <div className="services">
                    {data.map((d,index) => <Service key={index} category={d.category} description={d.description} icon={d.icon}/>)}
                </div>
            </div>
         </div>
     );
}
 
export default Portofolio;