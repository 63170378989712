import React from "react";
import "./button.css";

function Button(props){
    const onButtonClick=() => {
        const button={
            name:props.name,
            active:props.active
        }
        props.updateTimeButtons(button);
    };

    return(
        <button className={props.active ? "activeOption" : "option-button"} onClick={onButtonClick}>{props.name}</button>
    );
}

export default Button;