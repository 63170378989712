import React, { Component } from 'react';
import Home from "./home/home";
import Header from "./header/header";
import About from "./about/about";
import Portofolio from "./portofolio/portofolio";
import Contact from "./contact/contact";
import Footer from "./footer/footer";
import Services from "./services/services";
import "./companyPresentation.css";

class CompanyPresentation extends Component {
    state = {  }
    render() { 
        return (
            <React.Fragment>
                 <Header/>
                 <Home/>
                 <About/>
                 <Portofolio/>
                 <Services/>
                 {/*<Contact/>*/}
                 <Footer/>
            </React.Fragment>
         );
    }
}
 
export default CompanyPresentation;