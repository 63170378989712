import React from 'react'
import "./footer.css"
import ContactOption from "../contact/contactOption/contactOption";

function Footer() {
    let email=<a href="mailto:contact@fivetech.ro" style={{color: "white"}}>contact@fivetech.ro</a>
    let phone=<a href="tel:+40749397933" style={{color: "white"}}>+40 749 397 933</a>
    let data = [
        {
            icon: "fa fa-location-arrow",
            type: "Address",
            contact: "57A Calea Dorobantilor, Cluj-Napoca | Romania"

        },
        {
            icon: "fa fa-envelope-o",
            type: "Email",
            contact: email

        },
        {
            icon: "fa fa-phone",
            type: "Phone",
            contact: phone

        }
    ];
    return (
        <div className="container-fluid footer-section" id="contact">
            <div className="container">
                <div className="row footer-contacts-container">
                <div className="get-in-touch col-xl-6 col-md-6 col-12">
                    <h3>Get in touch</h3>
                    <p>Follow us on social media to keep up to date with all our news and do not hesitate to contact us using any contact info displayed near by.
                        </p>
                    <div className="social">
                        <div className="logo">
                            <img src={require("../../../images/fivetechWhiteLogoTransparent.png")}/>
                        </div>
                        <div className="icons">
                            <a href="https://www.facebook.com/fivetechsoftware/" target="_blank"><i className="fa fa-facebook"></i></a>
                            {/* <a href="https://www.instagram.com/kudos.tech/" target="_blank"><i className="fa fa-instagram"></i></a> */}
                            <a href="https://www.linkedin.com/company/fivetech-software/" target="_blank"><i className="fa fa-linkedin-square"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-4">
                    <div className="contact-info">
                        {data.map((d, index) => <ContactOption key={index} icon={d.icon} type={d.type} contact={d.contact} />)}
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Footer