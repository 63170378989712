import React, {useState} from "react";
import "./membership.css";
import Benefit from "./benefits/benefit"
import { Link } from 'react-router-dom'

function Membership(props){
    const [id, setId] = useState({value: ""});
    function setActive() {
        if(props.type!=="STANDARD"){
            const membership={
                type : props.type,
                active : true
            }
            props.updateActive(membership);
        } 
    }
    function setInactive() {
        if(props.type!=="STANDARD"){
            const membership={
                type : props.type,
                active : false
            }
            props.updateActive(membership);
        } 
    }

    let price=10000;
    function getPrice() {
        props.price.members.forEach(element => {
            if(element.price<price) price=element.price;
        });

        if(props.membershipType==="yearly"){
            price-=((20/100)*price);
        }
        return price;
    }

    return(
        <div className="membership-inactive col-12 col-lg-4 col-md-4" id={props.type==="STANDARD"&&props.active===true ? "active" : id.value} onMouseEnter={() =>{ setId({value : "active"}); setInactive() } } onMouseLeave={() =>{setId({value : ""}); setActive() }}>
            <div className="membership-type">
                <h3>{props.type}</h3>
            </div>
            <div className="membership-description">
                <span className="price">From {getPrice()} RON/MONTH</span>
                <ul>
                    {props.properties.map((p,index) => p.enabled===true ? <Benefit key={index} name={p.name} description={p.description} class="text col-10"/> : <Benefit key={index} name={p.name} description={p.description} class="denied col-10"/>)}
                </ul>
                <Link to={{
                    pathname : "/summary",
                    state: {
                    membership : {
                        id : props.id,
                        type : props.type,
                        prices: props.price,
                        price: price,
                        properties: props.properties,
                        period: props.membershipType

                    },
                }
                }} className="btn">PURCHASE NOW</Link>
            </div>
        </div>
    );
}

export default Membership;