import React from "react";
import "./home.css"; 

function Home(){
    return(
        <div className="container-fluid home-section" id="home">
            <div className="image-container">
            </div>
            <video autoPlay loop muted id="video">
                <source src={require('../../../videos/circuits2.mp4')} type="video/mp4"/>
            </video>
            <div className="container home-container">
                <div className="main-home">
                    <div className="box-container">
                        <div className="content-box">
                            <h2>Design, Build, Live on Air 🔴</h2>
                        </div>
                    </div>
                    <div className="button-container">
                        <a href="#about">
                            <i className="fa fa-4x fa-angle-down"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>    
    )
}

export default Home;