import React from "react";
import "./about.css";

function About() {
    return (
        <div className="container-fluid about-section" id="about">
            <div className="container">
                <div className="about-content row">
                    <div className="col-xl-12 col-12 about-description">
                        <h2 className="about-h2">Who we are</h2>
                        {/*<h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>*/}
                        <p>Fivetech is your partner agency focused on the maximum benefit with an inventive mindset and new creative ideas.
                           We are a young team trying to do the best for you and we’ll do it with pleasure.
                               </p><p>Ideas are important, yet most of all are starting points.
                        So…You tell us your idea and we put it into practice. We use the newest technologies and platforms for developing and implementing your projects.
                        Find a large area of development services and technologies:
                            </p>
                    </div>
                    <div className="col-xl-12 col-12 about-image-wrapper">
                        <img className="about-image" src={require('../../../images/fivetech_dev_cycle.png')} alt="aboutGym" />
                        <img className="about-image-mobile" src={require('../../../images/fivetech_dev_mobile_cycle.png')} alt="aboutGym" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;