import React, { Component } from 'react';
import { BrowserRouter, Route} from "react-router-dom";
import './App.css';
import CompanyPresentation from "./components/companyPresentation/companyPresentation"
import Summary from "./components/summary/summary";
import Price from "./components/price/price";
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {
  render() {
    return (
        <BrowserRouter>
          <Route exact path="/" component={CompanyPresentation}/>
          <Route exact path="/summary" component={Summary}/>
          <Route exact path="/price" component={Price}/>
        </BrowserRouter>
    );
  }
}

export default App;
