import React from 'react';
import "./navbar.css";
import logo from '../../../../images/fivetechLogoTransparent.png'

function Navbar(){
    function hideDropdown(){
        document.getElementById("toggler").click()
    }
    return(
        <nav className="navbar navbar-expand-lg  row">
            <h1 className="col-xl-6 col-8 col-md-3"><img src={logo} alt="logo"></img></h1>
            <button className="navbar-toggler col-3 col-md-2" id="toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon">
                    <i className="fa fa-bars fa-2x"></i>
                </span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav">
                    <li className="nav-item active" onClick={hideDropdown}>
                        <a className="nav-link" href="#home">Home</a>
                    </li>
                    <li className="nav-item" onClick={hideDropdown}>
                        <a className="nav-link" href="#about">About</a>
                    </li>
                    <li className="nav-item dropdown" onClick={hideDropdown}>
                        <a className="nav-link" href="#portofolio">Portofolio</a>
                    </li>
                    <li className="nav-item dropdown" onClick={hideDropdown}>
                        <a className="nav-link" href="#services">Services</a>
                    </li>
                    {/* <li className="nav-item" onClick={hideDropdown}>
                        <a className="nav-link" href="#contact">Contact</a>
                    </li> */}
                </ul>
            </div>
        </nav>
    )
}

export default Navbar;